import isNumber from 'lodash/isNumber'
import uniq from 'lodash/uniq'

const ADMITHUB_DISMISSED_ALERT_IDS_V1 = 'ADMITHUB_DISMISSED_ALERT_IDS_V1'
export function getDismissed(): number[] {
  let ids = []
  try {
    // NOTE(chdsbd): We should probably key by person. But then we need to have
    // some handling for unauthenticated users. I think this is okay for now.
    const parsedIsds = JSON.parse(
      localStorage.getItem(ADMITHUB_DISMISSED_ALERT_IDS_V1) || '[]'
    )
    if (Array.isArray(parsedIsds) && parsedIsds.map(isNumber)) {
      ids = parsedIsds
    }
    // tslint:disable-next-line:no-empty
  } catch {}
  return ids
}

export function addDismissedAlert(id: number) {
  const existingIds = getDismissed()
  const newIds = JSON.stringify(uniq([...existingIds, id]))
  localStorage.setItem(ADMITHUB_DISMISSED_ALERT_IDS_V1, newIds)
}
