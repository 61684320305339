import * as ReactDOM from 'react-dom'
import { store } from 'store/store'
import { Provider } from 'react-redux'
import { bootstrapErrorTracking } from 'bootstrapErrorTracking'
import App from 'page/App'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { initFontLibrary } from 'util/icons'
import 'scss/index.scss'
import 'fonts.css'
import en from 'i18n/en.json'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as settings from 'const/settings'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import scssVariables from 'scss/_variables.scss'

// note(jsimms): exporting this from a separate module doesnt work for some reason
// my suspicion is that its an issue with the interaction between react-i18next and
// the hot loader.
i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en,
    },
  })

const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: scssVariables.mainstayDarkBlue70,
      },
    },
  },
  palette: {
    primary: {
      main: scssVariables.admithubPrimary,
    },
    secondary: {
      main: scssVariables.mainstayElectricRed,
    },
    text: {
      primary: scssVariables.mainstayDarkBlue80,
    },
  },
})

bootstrapErrorTracking({ sentryDSN: settings.MARSHALL_UI_SENTRY_DSN })

initFontLibrary()

const rootEl = document.getElementById('root')

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Provider>
  </ErrorBoundary>,
  rootEl
)
