import * as React from 'react'
import pluralize from 'pluralize'
import { PieChart, Pie, Cell, Label, Legend } from 'recharts'
import { RoundedRectIcon } from 'components/Icons/RoundedRectIcon/RoundedRectIcon'
import 'page/CampaignDetailsPage.scss'
import styles from 'scss/_variables.scss'
import { notUndefined } from 'util/typeguards'
import { IEngagementCounts } from 'api/response'
import { Button } from 'components/Button/Button'
import { getDeliveryFailuresDetail, getReportPreferences } from 'api'
import { ICampaign } from 'store/campaign-history/reducer'
import { isRight } from 'fp-ts/lib/Either'
import { DateRangeCustomFieldsDownload } from 'page/IntroDialogDetailsPage'
import {
  CustomCampaignReportDownloadModal,
  IPreferencesRequestPayload,
} from 'components/TrendsV2/CustomizedDownloadModal'
import {
  isFailure,
  isUnresolved,
  Loading,
  Success,
  WebData,
} from 'store/webdata'
import { CenteredLoader } from 'components/Loader/Loader'

import { ICustomCampaignReportDownload } from 'components/CampaignResultsCard/CampaignResultsCard'
import Tooltip from 'components/Tooltip/Tooltip'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { MainstayModal } from 'components/Modal/Modal'
import { ReportType } from 'store/campaign-details/reducer'

const CAMPAIGN_SEND_FAILURE_MESSAGE =
  'Message failed to send due to a platform provider outage, code conflict, or some other rare issue. See the Delivery Failures report for details.'

type EngagementRecipientSlices =
  | 'active'
  | 'passive'
  | 'optedOut'
  | 'paused'
  | 'deliveryFailure'

export interface IEngagementRecipientData {
  name: EngagementRecipientSlices
  value: number
  includeMoreDetailButton?: boolean
  onSelectMoreDetail?: () => void
}

const ERROR_DESCRIPTION_URL =
  'https://support.admithub.com/hc/en-us/articles/360020256012'

const recipientSliceToLegendMapping: {
  [key in EngagementRecipientSlices]: string
} = {
  active: 'Active recipients',
  passive: 'Passive recipients',
  optedOut: 'Opt-outs',
  paused: 'Pauses',
  deliveryFailure: 'Impacted by failures',
}

const COLORMAP: { [key in EngagementRecipientSlices]: string } = {
  active: styles.mainstayElectricRed,
  passive: styles.mainstayDarkBlue,
  optedOut: styles.mainstayDarkMint,
  paused: styles.mainstayLightGreen,
  deliveryFailure: styles.mainstayDarkBlue20,
}

// NOTE: this is the desired order of items in the legend
const LEGEND_LIST: EngagementRecipientSlices[] = [
  'active',
  'passive',
  'optedOut',
  'paused',
  'deliveryFailure',
]

export const getLegendList = (isRespondable: boolean) => {
  if (!isRespondable) {
    return LEGEND_LIST.filter(item => item !== 'active')
  }
  return LEGEND_LIST
}

const PieChartLabel = ({ total, ...rest }: { total: number }) => {
  return (
    <svg
      width={85}
      height={85}
      viewBox="0 0 85 85"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      style={{ fill: styles.mainstayAlmostBlack }}>
      <text className="d-flex flex-column">
        <tspan
          x="50%"
          y="50%"
          style={{ fill: styles.mainstayAlmostBlack, fontSize: '20px' }}>
          {total}
        </tspan>
        <tspan
          x="50%"
          y="70.5%"
          style={{ fill: styles.mainstayGrayText, fontSize: '16px' }}>
          {pluralize('contact', total)}
        </tspan>
      </text>
    </svg>
  )
}

const PieChartLegendItem = ({
  name,
  value,
  includeMoreDetailButton,
  onSelectMoreDetail,
}: IEngagementRecipientData) => {
  return (
    <div className="d-flex align-items-center justify-content-between mainstay-body-caption">
      <div className="d-flex align-items-center">
        <span className="mr-2 mb-1">
          <RoundedRectIcon width={16} height={16} fill={COLORMAP[name]} />
        </span>
        <span className="space-after">
          {recipientSliceToLegendMapping[name]}
        </span>
        {includeMoreDetailButton && (
          <Button
            eventLocation="campaigns"
            eventAction="click"
            eventObject="delivery failures modal open"
            color="link"
            className="mainstay-body-caption p-0 text-mainstay-teal"
            onClick={onSelectMoreDetail}>
            More Detail
          </Button>
        )}
      </div>
      <div>{value}</div>
    </div>
  )
}

const DeliveryFailureRow = ({
  message,
  count,
}: {
  message: React.ReactNode
  count: number
}) => (
  <div className="d-flex justify-content-between">
    {message} <span>{count}</span>
  </div>
)

const SendErrorRow = ({
  errorCode,
  errorCount,
  errorDescription,
}: {
  errorCount: number
  errorCode: string
  errorDescription: string
}) => {
  const message = (
    <span>
      <a
        className="text-underline text-mainstay-teal"
        href={ERROR_DESCRIPTION_URL}
        target="_blank">
        {errorCode}
      </a>{' '}
      {errorDescription}
    </span>
  )
  return <DeliveryFailureRow message={message} count={errorCount} />
}

interface IDeliveryFailuresDetail {
  campaignName: string
  deliveryFailuresMessages: {
    [id: string]: { count: number; description: string }
  }
  deliveryFailuresUserCount: number
  deliveryFailuresMessagesCount: number
  priorOptOutsCount: number
  priorPausedCount: number
  failedUsersCount: number
  priorInvalidPhoneUsersCount: number
}

interface IDeliveryFailuresDetailModal {
  isOpen: boolean
  isRecurring: boolean
  isAggregate: boolean
  isDataTriggered?: boolean
  campaignId: ICampaign['id']
  audience?: string
  countCampaignsSent: number | undefined
  onClose: () => void
}
const DeliveryFailuresDetailModalContainer = ({
  isOpen,
  children,
  onClose,
  onSubmit,
  isAggregate,
}: Omit<
  IDeliveryFailuresDetailModal,
  | 'campaignId'
  | 'isRecurring'
  | 'countCampaignsSent'
  | 'audience'
  | 'isDataTriggered'
> & {
  children: React.ReactNode
  onSubmit?: () => void
}) => {
  return (
    <MainstayModal
      show={isOpen}
      className="max-width-500"
      text={`${isAggregate ? 'Aggregate ' : ''}Delivery Failures`}
      onClose={onClose}
      submitTrackingEvent={{
        location: 'campaigns',
        action: 'click',
        object: 'delivery failures download report modal open',
      }}
      cancelTrackingEvent={{
        location: 'campaigns',
        action: 'click',
        object: 'delivery failures modal close',
      }}
      onSubmit={onSubmit}
      onCancel={onClose}
      submitText="Download Report"
      cancelText="Close">
      {children}
    </MainstayModal>
  )
}

const DeliveryFailuresDetailModal = ({
  isOpen,
  isRecurring,
  isAggregate,
  isDataTriggered,
  campaignId,
  reportFileName,
  countCampaignsSent,
  onClose,
  audience,
  generateCampaignReport,
}: IDeliveryFailuresDetailModal & ICustomCampaignReportDownload) => {
  const [deliveryFailuresData, setDeliveryFailuresData] = React.useState<
    WebData<IDeliveryFailuresDetail>
  >(Loading())

  const [showDownloadModal, setShowDownloadModal] = React.useState(false)

  const [isDownloading, setDownloading] = React.useState(false)

  React.useEffect(() => {
    getDeliveryFailuresDetail(
      campaignId,
      isRecurring,
      isAggregate,
      audience,
      isDataTriggered
    ).then(res => {
      if (isRight(res)) {
        const {
          name,
          delivery_failures_messages,
          delivery_failures_messages_count,
          total_delivery_failures_users_count,
          prior_paused_count,
          prior_opt_outs_count,
          prior_invalid_phone_users_count,
          failed_users,
        } = res.right
        setDeliveryFailuresData(
          Success({
            campaignName: name,
            deliveryFailuresMessages: delivery_failures_messages,
            deliveryFailuresUserCount: total_delivery_failures_users_count,
            deliveryFailuresMessagesCount: delivery_failures_messages_count,
            priorOptOutsCount: prior_opt_outs_count,
            priorPausedCount: prior_paused_count,
            priorInvalidPhoneUsersCount: prior_invalid_phone_users_count,
            failedUsersCount: failed_users,
          })
        )
      }
    })
  }, [campaignId, isRecurring, isDataTriggered, isAggregate, audience])

  const getPreferenceData = React.useCallback(
    () =>
      getReportPreferences({
        insight: isDataTriggered
          ? 'data_triggered_campaign_delivery_failures'
          : 'delivery_failures',
        isRecurring,
        isDataTriggered,
        campaignId,
      }),
    [campaignId, isRecurring, isDataTriggered]
  )

  if (isFailure(deliveryFailuresData)) {
    return (
      <DeliveryFailuresDetailModalContainer
        isOpen={isOpen}
        onClose={onClose}
        isAggregate={isAggregate}>
        <span> Unable to fetch data </span>
      </DeliveryFailuresDetailModalContainer>
    )
  }

  const handleDownload = (preferences: IPreferencesRequestPayload) => {
    if (generateCampaignReport) {
      setDownloading(true)
      generateCampaignReport({
        preferences,
        reportFileName,
        isAggregate,
        workflowResponses: undefined,
        campaignReportType: isDataTriggered
          ? ReportType.TriggerDeliveryFailures
          : ReportType.CampaignDeliveryFailures,
        onDownloadReportComplete: () => {
          setShowDownloadModal(false)
          setDownloading(false)
        },
        audience,
      })
    }
  }

  return (
    <>
      {!isDataTriggered && (
        <CustomCampaignReportDownloadModal
          reportTitle={
            isAggregate
              ? 'Aggregate Delivery Failures Report'
              : 'Delivery Failures Report'
          }
          initialFileName={`${reportFileName ?? 'report_delivery'}_failures`}
          show={showDownloadModal}
          getPreferenceData={getPreferenceData}
          onClose={() => setShowDownloadModal(false)}
          submissionInProgress={isDownloading}
          countCampaignsSent={countCampaignsSent}
          onSubmit={handleDownload}
          isAggregate={isAggregate}
          eventLocation="campaigns"
          eventObject="delivery failures"
        />
      )}
      {isDataTriggered && showDownloadModal && (
        <DateRangeCustomFieldsDownload
          isAggregate={isAggregate}
          hideCampaignHistory={true}
          isDownloading={isDownloading}
          reportTitle={
            isAggregate
              ? 'Aggregate Delivery Failures Report'
              : 'Delivery Failures Report'
          }
          initialFileName={`${reportFileName ?? 'report_delivery'}_failures`}
          getPreferenceData={getPreferenceData}
          onSubmit={handleDownload}
        />
      )}
      <DeliveryFailuresDetailModalContainer
        isOpen={isOpen}
        isAggregate={isAggregate}
        onClose={onClose}
        onSubmit={() => {
          setShowDownloadModal(true)
          onClose()
        }}>
        {isUnresolved(deliveryFailuresData) ? (
          <CenteredLoader />
        ) : (
          <div>
            <p>
              "{deliveryFailuresData.data.campaignName}" campaign encountered
              errors while sending.
            </p>
            <DeliveryFailureRow
              message="Recipients impacted by failures"
              count={deliveryFailuresData.data.deliveryFailuresUserCount}
            />
            <DeliveryFailureRow
              message="Total failures"
              count={deliveryFailuresData.data.deliveryFailuresMessagesCount}
            />
            <hr />
            <div className="mt-3">
              <div>
                <span className="fw-600">Send Failures</span>
              </div>
              <DeliveryFailureRow
                message="Messages blocked by prior opt-out status"
                count={deliveryFailuresData.data.priorOptOutsCount}
              />
              <DeliveryFailureRow
                message="Messages blocked by prior #pause status"
                count={deliveryFailuresData.data.priorPausedCount}
              />
              <DeliveryFailureRow
                message="Messages blocked by prior invalid phone status"
                count={deliveryFailuresData.data.priorInvalidPhoneUsersCount}
              />
              {deliveryFailuresData.data.failedUsersCount > 0 && (
                <DeliveryFailureRow
                  message={
                    <span>
                      Mainstay sending issues
                      <Tooltip
                        content={
                          <span className="text-mainstay-dark-blue-80">
                            {CAMPAIGN_SEND_FAILURE_MESSAGE}
                          </span>
                        }>
                        <span className="action-btn ml-1">
                          <AHIcon
                            className="text-mainstay-dark-blue-65"
                            name="help"
                          />
                        </span>
                      </Tooltip>
                    </span>
                  }
                  count={deliveryFailuresData.data.failedUsersCount}
                />
              )}
            </div>
            <div>
              <div className="mt-3">
                <span className="fw-600">SMS Failures</span>
              </div>
              {Object.keys(
                deliveryFailuresData.data.deliveryFailuresMessages
              ).map(key => {
                return (
                  <SendErrorRow
                    key={key}
                    errorCode={key}
                    errorDescription={
                      deliveryFailuresData.data.deliveryFailuresMessages[key]
                        .description
                    }
                    errorCount={
                      deliveryFailuresData.data.deliveryFailuresMessages[key]
                        .count
                    }
                  />
                )
              })}
            </div>
          </div>
        )}
      </DeliveryFailuresDetailModalContainer>
    </>
  )
}

const PieChartLegend = ({
  data,
  campaignId,
  reportFileName,
  legendList,
  isRecurring,
  isAggregate,
  isDataTriggered,
  audience,
  countCampaignsSent,
  generateCampaignReport,
}: {
  data: IEngagementRecipientData[]
  campaignId: ICampaign['id']
  legendList: EngagementRecipientSlices[]
  isRecurring: boolean
  isDataTriggered?: boolean
  audience?: string
  isAggregate: boolean
  countCampaignsSent: number | undefined
} & ICustomCampaignReportDownload) => {
  const [
    showDeliveryFailuresDetail,
    setShowDeliveryFailuresDetail,
  ] = React.useState(false)

  const hasDeliverabilityIssues =
    data.reduce((acc, entry) => {
      if (['optedOut', 'deliveryFailure'].includes(entry.name)) {
        return acc + entry.value
      }
      return acc
    }, 0) > 0

  return (
    <>
      <DeliveryFailuresDetailModal
        campaignId={campaignId}
        isRecurring={isRecurring}
        isAggregate={isAggregate}
        isDataTriggered={isDataTriggered}
        audience={audience}
        isOpen={showDeliveryFailuresDetail}
        onClose={() => setShowDeliveryFailuresDetail(false)}
        reportFileName={reportFileName}
        generateCampaignReport={generateCampaignReport}
        countCampaignsSent={countCampaignsSent}
      />
      <div className="d-flex flex-column h-100">
        {legendList.map(item => {
          const datum = data
            .filter(datum => datum.name === item)
            .filter(notUndefined)[0]
          return (
            <PieChartLegendItem
              key={datum.name}
              name={datum.name}
              value={datum.value}
              includeMoreDetailButton={
                hasDeliverabilityIssues && datum.name === 'deliveryFailure'
              }
              onSelectMoreDetail={() => {
                if (datum.name === 'deliveryFailure') {
                  setShowDeliveryFailuresDetail(true)
                }
              }}
            />
          )
        })}
      </div>
    </>
  )
}

interface ICampaignEngagementDonutChartProps {
  campaignId: ICampaign['id']
  data: IEngagementRecipientData[]
  totalIntendedRecipients: number
  legendList: EngagementRecipientSlices[]
  isRecurring: boolean
  isAggregate: boolean
  isDataTriggered?: boolean
  audience?: string
  countCampaignsSent: number | undefined
}

export const CampaignEngagementDonutChart = ({
  campaignId,
  data,
  totalIntendedRecipients,
  reportFileName,
  legendList,
  isRecurring,
  isDataTriggered,
  isAggregate,
  audience,
  countCampaignsSent,
  generateCampaignReport,
}: ICampaignEngagementDonutChartProps & ICustomCampaignReportDownload) => {
  return (
    <div className="d-flex align-items-center">
      <PieChart width={150} height={200}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          innerRadius={60}
          outerRadius={72}
          dataKey="value">
          {data.map(datum => {
            return <Cell key={datum.name} fill={`${COLORMAP[datum.name]}`} />
          })}
          <Label
            position="center"
            content={() => {
              const positioningProps = {
                x: '22%',
                y: '25%',
                textAnchor: 'middle',
                verticalAnchor: 'middle',
                width: 85, // this width makes it so that most labels remain on one line, and much larger campaigns will have 2-line labels instead of overlapping the edges of the donut
              }
              return (
                <PieChartLabel
                  {...positioningProps}
                  total={totalIntendedRecipients}
                />
              )
            }}
          />
        </Pie>
        {/* wrapperStyle is critical here for aligning the custom Legend component to the right of the donut */}
        <Legend
          verticalAlign="middle"
          width={278}
          wrapperStyle={{
            position: 'relative',
            left: '180px',
            bottom: '164px',
          }}
          content={
            <PieChartLegend
              data={data}
              campaignId={campaignId}
              reportFileName={reportFileName}
              generateCampaignReport={generateCampaignReport}
              legendList={legendList}
              isRecurring={isRecurring}
              audience={audience}
              isAggregate={isAggregate}
              isDataTriggered={isDataTriggered}
              countCampaignsSent={countCampaignsSent}
            />
          }
        />
      </PieChart>
    </div>
  )
}

interface ICampaignEngagementSectionProps {
  campaignId: ICampaign['id']
  engagementData: IEngagementCounts
  isRespondable: boolean
  isAggregate: boolean
  isRecurring: boolean
  isDataTriggered?: boolean
  countCampaignsSent: number | undefined
  audience?: string
}

export const CampaignEngagementSection = ({
  campaignId,
  engagementData,
  reportFileName,
  isRespondable,
  isRecurring,
  isAggregate,
  isDataTriggered,
  countCampaignsSent,
  generateCampaignReport,
  audience,
}: ICampaignEngagementSectionProps & ICustomCampaignReportDownload) => {
  const mapEngagementCountsResponseToSlices = (): {
    engagementSlices: IEngagementRecipientData[]
    totalIntendedRecipients: number
  } => {
    const {
      passiveUsersCount,
      activeUsersCount,
      resultingSoftStopUsersCount,
      resultingHardStopUsersCount,
      deliveryFailureUsersCount,
      totalIntendedRecipients,
    } = engagementData

    const engagementSlices: IEngagementRecipientData[] = [
      {
        name: 'passive',
        value: passiveUsersCount,
      },
      {
        name: 'deliveryFailure',
        value: deliveryFailureUsersCount,
      },
      {
        name: 'paused',
        value: resultingSoftStopUsersCount,
      },
      {
        name: 'optedOut',
        value: resultingHardStopUsersCount,
      },
    ]

    if (isRespondable) {
      engagementSlices.push({
        name: 'active',
        value: activeUsersCount,
      })
    }

    return { engagementSlices, totalIntendedRecipients }
  }

  const legendList = getLegendList(isRespondable)

  const {
    engagementSlices,
    totalIntendedRecipients,
  } = mapEngagementCountsResponseToSlices()

  return (
    <>
      {isAggregate ? (
        <div className="pb-0 d-flex align-items-center">
          <span className="text-mainstay-dark-blue mainstay-header-h4-overview">
            Aggregate Campaign Engagement
            <Tooltip
              content={
                <span className="text-mainstay-dark-blue-80">
                  Contacts who were sent this campaign multiple times are
                  counted for each campaign send.
                </span>
              }>
              <span className="ml-1 action-btn">
                <AHIcon
                  name="help"
                  className="text-mainstay-dark-blue-65"
                  overrideWidth={true}
                  style={{
                    width: '16px',
                    height: '16px',
                    fontSize: '1em',
                    top: '2px',
                  }}
                />
              </span>
            </Tooltip>
          </span>
        </div>
      ) : (
        <div className="pb-0 text-mainstay-dark-blue mainstay-header-h4-overview">
          Campaign Engagement
        </div>
      )}
      {Object.values(engagementData).some((value: number) => value > 0) ? (
        <CampaignEngagementDonutChart
          data={engagementSlices}
          totalIntendedRecipients={totalIntendedRecipients}
          campaignId={campaignId}
          isDataTriggered={isDataTriggered}
          legendList={legendList}
          reportFileName={reportFileName}
          audience={audience}
          isRecurring={isRecurring}
          isAggregate={isAggregate}
          countCampaignsSent={countCampaignsSent}
          generateCampaignReport={generateCampaignReport}
        />
      ) : audience ? (
        'There were no campaign recipients in this Audience.'
      ) : (
        'There were no recipients for this campaign.'
      )}
    </>
  )
}
