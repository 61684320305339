import { useEffect, useState } from 'react'
import Markdown from 'markdown-to-jsx'
import { Alert } from 'reactstrap'
import * as api from 'api'
import { IApplicationAlertBannerResponse } from 'api/response'
import { getDismissed, addDismissedAlert } from 'components/AppAlert/util'
import 'components/AppAlert/AppAlert.scss'

const EMPTY_RESPONSE = {
  banner_exists: false as const,
}

export function AppAlert() {
  const [alert, setAlert] = useState<IApplicationAlertBannerResponse>(
    EMPTY_RESPONSE
  )
  const [dismissedIds, setDismissedIds] = useState<number[]>([])
  const [open, toggleOpen] = useState(true)

  useEffect(() => {
    api
      .getApplicationAlertBanner()
      .then(res => {
        setAlert(res.data)
      })
      .catch(() => {
        // don't show anything
        setAlert(EMPTY_RESPONSE)
      })
  }, [])
  useEffect(() => {
    setDismissedIds(getDismissed())
  }, [])

  function onClose() {
    if (alert.banner_exists) {
      addDismissedAlert(alert.id)
    }
    toggleOpen(false)
    setAlert(EMPTY_RESPONSE)
  }

  if (!alert.banner_exists || dismissedIds.includes(alert.id)) {
    return null
  }
  return (
    <Alert
      color="warning"
      className="mb-0 ah-app-alert shadow"
      isOpen={open}
      toggle={onClose}>
      <Markdown
        // Ensure that we only have basic markup like bold, italic, links, code
        // snippets (not code blocks) via `forceInline`. Anything else will
        // render as normal text.
        options={{ forceInline: true }}
        // <Markdown/> is specific about only having markdown as a child
        children={alert.message_markdown}
      />
    </Alert>
  )
}
