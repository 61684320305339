import React, { useState, Ref, useEffect } from 'react'
import { DropdownToggle, Dropdown, ModalBody } from 'reactstrap'
import classnames from 'classnames'
import Select from 'components/Select/SelectV2'
import 'components/LanguageSelection/LanguageSelectionDropdown.scss'
import { OptionProps } from 'react-select/lib/components/Option'
import { InputProps } from 'react-select/lib/components/Input'
import { MenuProps } from 'react-select/lib/components/Menu'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { TextInput } from 'components/TextInput/TextInput'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import { Button } from 'components/Button/Button'
import FocusTrap from 'focus-trap-react'

interface ILanguageSelectDropdownProps {
  value: string
  languages: {
    code: string
    name: string
  }[]
  onSelect: (value: string) => void
  menuOpen: boolean
  toggleMenu: React.Dispatch<React.SetStateAction<boolean>>
}
interface ILanguageOption {
  code: string
  name: string
}
interface ICustomLanguageOption extends OptionProps<ILanguageOption> {
  data: ILanguageOption
}

export const CustomOptionWithValue = (props: ICustomLanguageOption) => {
  const classNames = classnames(
    'd-flex flex-row flex-shrink-0 px-1 justify-content-around pointer py-1',
    'lang-dropdown-option',
    {
      'font-weight-bold text-white bg-mainstay-dark-blue-65': props.isSelected,
    },
    { 'lang-dropdown--active': props.isFocused }
  )
  const optionNameId = `language-option-${props.data.code}`
  return (
    <div
      className={classNames}
      ref={props.innerRef}
      {...props.innerProps}
      key={optionNameId}
      aria-labelledby={optionNameId}>
      <span className="flex-grow-1" id={optionNameId}>
        {props.data.name}
      </span>
      <span className="pr-1">{props.data.code}</span>
    </div>
  )
}

interface ILanguageInputProps extends InputProps {
  innerRef: (element: Ref<HTMLInputElement>) => void
  onClose: () => void
  ariaExpanded: boolean
}

const LanguageInput = (props: ILanguageInputProps) => {
  const { onClose, ariaExpanded, ...rest } = props
  return (
    <div
      role="combobox"
      aria-label="Language Select Input"
      aria-expanded={ariaExpanded}
      aria-controls="language-select-dropdown"
      aria-haspopup="listbox"
      className={classnames('search-input d-flex', props.className)}>
      <div className="search-input-prepend">
        <AHIcon name="search" />
      </div>
      <TextInput
        {...rest}
        autoFocus
        className="plain-input pr-0 flex-grow-1"
        placeholder="Search"
        ariaLabel="Search for language"
        onBlur={undefined}
        type="search"
      />
      <Button
        onClick={props.onClose}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation()
            e.preventDefault()
            props.onClose()
          }
        }}
        aria-label="Close Language Dialog"
        className="bg-transparent px-0 ml-2 d-flex flex-colum justify-content-around align-items-center pointer">
        <AHIcon name="close" />
      </Button>
    </div>
  )
}

const Menu = (props: MenuProps<ILanguageOption>) => {
  return (
    <>
      <small className="text-muted d-block pt-3 pb-1">Translate to</small>
      <div className="d-flex flex-grow-1 scroll-y" style={{ height: '78%' }}>
        <div
          aria-label="Language Select Dropdown"
          role="listbox"
          className="d-flex flex-column flex-grow-1 py-1 text-mainstay-dark-blue"
          {...props.innerProps}>
          {props.children}
        </div>
      </div>
    </>
  )
}

const Empty: React.FC = props => <>{props.children}</>

const LanguageSelectModal = (props: {
  children: React.ReactNode
  isOpen: boolean
}) => {
  return (
    <FocusTrap
      focusTrapOptions={{ allowOutsideClick: true }}
      active={props.isOpen}>
      <div
        tabIndex={0}
        className="language-select-modal modal-content modal-shadow border-0">
        <ModalBody>{props.children}</ModalBody>
      </div>
    </FocusTrap>
  )
}

export const LanguageSelectDropdown = ({
  value,
  languages,
  menuOpen,
  onSelect,
  toggleMenu,
}: ILanguageSelectDropdownProps) => {
  const [selectedLanguage, selectLanguage] = useState(value)
  useEffect(() => {
    selectLanguage(value)
  }, [value])

  const selectedLanguageOption = languages.find(
    x => x.code === selectedLanguage
  )

  // show the selected language on top when displaying options
  const languageOptions = !!selectedLanguageOption
    ? [
        selectedLanguageOption,
        ...languages.filter(({ code }) => code !== selectedLanguage),
      ]
    : languages

  const languageSelectButtonAriaLabel =
    selectedLanguage && selectedLanguageOption
      ? `${selectedLanguage.toUpperCase()}. ${
          selectedLanguageOption.name
        } currently selected. Language Select Dropdown`
      : 'Language Select Dropdown'
  return (
    <OutsideClickHandler
      containerClassName="d-flex flex-grow-1"
      onClickOutside={() => toggleMenu(false)}>
      <Dropdown
        className="position-static w-100"
        isOpen={menuOpen}
        toggle={() => {
          toggleMenu((prevValue: boolean) => !prevValue)
        }}>
        <DropdownToggle
          style={{ textTransform: 'capitalize' }}
          className={classnames(
            'bg-white border-dark btn-sm hover hover-text-mainstay-dark-blue-80 hover-border-mainstay-dark-blue-80 focus-mainstay-dark-blue-80',
            {
              'text-mainstay-dark-blue-80 border-mainstay-dark-blue-80 bg-blue-grey-015': menuOpen,
              'text-mainstay-dark-blue-70 border-mainstay-dark-blue-70': !menuOpen,
            }
          )}
          aria-expanded={menuOpen}
          aria-label={languageSelectButtonAriaLabel}>
          {selectedLanguage} <AHIcon name="arrow_drop_down" />
        </DropdownToggle>
        {menuOpen && (
          <div className="lang-dropdown px-1 py-1 d-flex flex-grow-1 h-100">
            {!languages.length ? (
              <div className="lang-dropdown-inner pt-1 d-flex flex-column flex-grow-1">
                Supported languages failed to load, please refresh the page to
                try again
              </div>
            ) : (
              <LanguageSelectModal isOpen={menuOpen}>
                <Select<ILanguageOption>
                  autoFocus
                  id="language-select-dropdown"
                  backspaceRemovesValue={false}
                  className="language-select"
                  styles={{
                    container: provided => ({
                      ...provided,
                      height: '100%',
                    }),
                    menuList: provided => ({
                      ...provided,
                      maxHeight: '650px',
                      paddingRight: '8px',
                    }),
                  }}
                  components={{
                    ValueContainer: Empty,
                    Placeholder: _props => null,
                    Control: Empty,
                    Input: props => (
                      <LanguageInput
                        {...props}
                        ariaExpanded={menuOpen}
                        onClose={() => toggleMenu(false)}
                      />
                    ),
                    DropdownIndicator: null,
                    IndicatorSeparator: null,
                    Menu,
                    Option: CustomOptionWithValue,
                  }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen={menuOpen}
                  tabSelectsValue={false}
                  onChange={(value, _meta) => {
                    if (Array.isArray(value)) {
                      return
                    } else if (value && value.code) {
                      selectLanguage(value.code)
                      onSelect(value.code)
                      toggleMenu(false)
                    }
                  }}
                  getOptionLabel={option => option.name}
                  options={languageOptions}
                  placeholder="Search..."
                  isOptionSelected={(option, options) =>
                    options.includes(option)
                  }
                  value={selectedLanguageOption}
                />
              </LanguageSelectModal>
            )}
          </div>
        )}
      </Dropdown>
    </OutsideClickHandler>
  )
}

export default LanguageSelectDropdown
