import React from 'react'

import classnames from 'classnames'
import Tooltip from 'components/Tooltip/Tooltip'
import { AHIcon, AHIconType } from 'components/Icons/AHIcon/AHIcon'
import scssVariables from 'scss/_variables.scss'
import { Button } from 'components/Button/Button'

export interface IFancyMenuProps {
  sendButton: React.ReactElement
  menuContent?: React.ReactElement
  counter?: React.ReactElement
  menuExtra?: React.ReactElement
}

interface IFancyTextBoxSendButtonProps {
  readonly disabled: boolean
  readonly disabledMessage?: string
  readonly onClick: () => void
  readonly color?: string
}

export const FancyTextBoxSendButton = ({
  disabled,
  disabledMessage,
  onClick,
  color,
}: IFancyTextBoxSendButtonProps) => {
  const sendIconColor = color ?? scssVariables.mainstayLightGreen
  return (
    <Tooltip
      touchHold
      content={
        !disabled
          ? 'Send message.'
          : disabledMessage || 'Cannot send empty message.'
      }>
      <Button
        className={classnames(
          'position-absolute',

          'fancy-before',
          disabled && 'opacity-65',
          'p-0',
          'bg-transparent'
        )}
        aria-label="Send Message"
        style={{ bottom: '5px', right: '5px' }}
        onClick={onClick}>
        {' '}
        <AHIcon
          className="fs-1_75rem"
          name="send"
          style={{ color: sendIconColor }}
        />
      </Button>
    </Tooltip>
  )
}

export const FancyTextboxMenuItemButton = (props: {
  active: boolean
  onClick: () => void
  name: AHIconType
  className?: string
  ariaLabel: string
  ariaHaspopup?: boolean
  ariaExpanded?: boolean
}) => {
  const buttonClassnames = classnames(
    {
      'text-mainstay-dark-blue-80': props.active,
      'bg-blue-grey-015': props.active,
      'text-mainstay-dark-blue-70': !props.active,
    },
    'focus-mainstay-dark-blue-70',
    'hover-text-mainstay-dark-blue-80',
    'h4',
    'p-1',
    'pointer',
    'rounded',
    props.className
  )

  return (
    <AHIcon
      ariaLabel={props.ariaLabel}
      ariaHaspopup={props.ariaHaspopup}
      ariaExpanded={props.ariaExpanded}
      className={buttonClassnames}
      name={props.name}
      onClick={props.onClick}
    />
  )
}
