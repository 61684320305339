import * as Sentry from '@sentry/browser'
import {
  RumEventDomainContext,
  datadogRum,
  RumEvent,
} from '@datadog/browser-rum'
import { loadAuthState } from 'store/localStorage'

type environments = 'dev' | 'staging' | 'production' | 'unknown'
/** Retrieve the environment from the current window.location
 *
 * `process.env.NODE_ENV` is always set in our environments, so it doesn't help
 * us in Sentry when we look by environment.
 */
function getEnvironmentFromURL(location: string): environments {
  /** our dev app is at app.dev.mainstay.com */
  if (location.includes('app.dev.mainstay.com')) {
    return 'dev'
  }
  /** our staging app is at app.staging.mainstay.com */
  if (location.includes('app.staging.mainstay.com')) {
    return 'staging'
  }
  /** our production app is at app.mainstay.com */
  if (location.includes('app.mainstay.com')) {
    return 'production'
  }
  /* set an environment for unknown so we can easily filter in Sentry */
  return 'unknown'
}

function getDatadogEnvironment(
  env: environments
): 'dev' | 'staging' | 'production' | 'unknown' {
  switch (env) {
    case 'production': {
      return 'production'
    }
    case 'staging': {
      return 'staging'
    }
    case 'dev': {
      return 'dev'
    }
    default: {
      return 'unknown'
    }
  }
}

/**
 * setup error tracking
 */
export function bootstrapErrorTracking({
  enableRum = true,
  sentryDSN,
}: {
  readonly enableRum?: boolean
  readonly sentryDSN: string
}) {
  const environment = getEnvironmentFromURL(window.location.hostname)
  const authState = loadAuthState()

  if (environment !== 'unknown') {
    datadogRum.init({
      applicationId: 'ea8b68b6-9437-4015-b8d4-87c031d3d8b8',
      beforeSend: (event: RumEvent, _context: RumEventDomainContext) => {
        datadogRum.setUser({
          id: authState?.userID,
          email: authState?.userEmail ?? '',
          name: authState?.userName ?? '',
          botId: authState?.orgSlug ?? '',
        })
        if (
          event.type === 'resource' &&
          (event.resource?.status_code ?? -1) >= 500
        ) {
          datadogRum.addError(
            `${event.resource.method} ${event.resource.url} ${event.resource.status_code}`
          )
        }
        return true
      },
      clientToken: 'pub6dbcf779eb4c67550677faa74e71c3d4',
      site: 'datadoghq.com',
      service: 'marshall-frontend',
      env: getDatadogEnvironment(environment),
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      proxy: (options: { path: string; parameters: string }) =>
        `https://rum.mainstay.com${options.path}/?${options.parameters}`,
    })

    if (authState?.authed && enableRum) {
      datadogRum.startSessionReplayRecording()

      // Integrate Sentry with DataDog RUM
      Sentry.configureScope(scope => {
        scope.setExtra(
          'dataDogSessionReplayURL',
          datadogRum.getSessionReplayLink() ?? ''
        )
      })
    }
  }

  Sentry.init({
    dsn: sentryDSN,
    environment,
  })

  /* Store release on window for easy access since we don't have console in prod */
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  /* tslint:disable:no-any no-unsafe-any */
  ;(window as any).environment = process.env.NODE_ENV
  /* tslint:enable:no-any no-unsafe-any */
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}
