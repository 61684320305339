import * as api from 'api'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { Card } from 'components/Card/Card'
import { Spinner } from 'components/Spinner/Spinner'
import { isLeft } from 'fp-ts/lib/Either'
import NoMatch from 'page/NoMatch'
import { InternalToolsPageContainer } from 'page/internal-tools/InternalToolsPageContainer'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { keyOrDefault } from 'api/http'
import axios, { CancelTokenSource } from 'axios'
import { ActionButton } from 'components/ActionButton/ActionButton'
import { Button } from 'components/Button/Button'
import { ActionMenu } from 'components/ActionMenu/ActionMenu'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { BotIconFilled } from 'components/Icons/BotIcon/BotIcon'
import { MoreIcon } from 'components/Icons/MoreIcon/MoreIcon'
import { RefreshIcon } from 'components/Icons/Refresh/Refresh'
import { Pager } from 'components/LinkPager/LinkPager'
import { CenteredLoader } from 'components/Loader/Loader'
import { MainstayModal } from 'components/Modal/Modal'
import { PageHeader } from 'components/PageHeader/PageHeader'
import { PanelDrawer } from 'components/Panel/Panel'
import { PanelDrawerHeader } from 'components/PanelDrawerHeader/PanelDrawerHeader'
import { Pill } from 'components/Pill/Pill'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import { SearchInput } from 'components/SearchInput/SearchInput'
import Select from 'components/Select/SelectV2'
import { TextArea } from 'components/TextArea/TextArea'
import { TextInput } from 'components/TextInput/TextInput'
import ThinkingEllipses from 'components/ThinkingEllipses/ThinkingEllipses'
import { FancyTextArea } from 'embed/components/WebChatFancyTextArea'
import { FancyTextBoxSendButton } from 'embed/components/WebChatFancyTextBoxHelpers'
import {
  MainstayFlexTableCol,
  MainstayFlexTableHeaderCol,
} from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import {
  MainstayFlexTableHeader,
  MainstayFlexTableRow,
} from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import moment from 'moment'
import NavBarContainer from 'page/NavBarPage'
import { Message } from 'page/conversations-v2/ConversationsDetail/Message/Message'
import 'page/internal-tools/KnowledgeBaseScraping.scss'
import KnowledgeSideBar from 'page/knowledge-base/KnowledgeSideBar/KnowledgeSideBarConnected'
import ButtonGroup from 'reactstrap/lib/ButtonGroup'
import {
  useDebounce,
  useFeatures,
  useInterval,
  useSelector,
  useDispatch,
} from 'util/hooks'
import {
  appendQueryFilter,
  getQueryFilters,
  removeQueryFilter,
} from 'util/queryFilters'
import { isUndefined } from 'lodash'
import { Field, FieldProps, Formik } from 'formik'
import { IOption } from 'components/AsyncCampaignSearch/AsyncCampaignSearch'
import * as yup from 'yup'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { setMostRecentAIResponse } from 'store/ask-ai/actions'
import { MessageOrigin } from 'page/conversations-v2/ConversationsDetail'
import { VALID_URL_REGEX } from 'components/AttributeDrawer/AttributeFormValidationSchema'

const FETCH_SCRAPE_ATTEMPTS_DELAY_MS = 5000
const FETCH_KNOWLEDGE_SOURCES_DELAY_MS = 5000
const KNOWLEDGE_SOURCES_PAGE_SIZE = 6

interface IAudienceFromContactLabels {
  sourceType: SourceTypeValue
  originUrl: string
}

type SourceTypeValue = keyof typeof api.GenAIScapeKnowledgeSourceType

const ASK_THE_AI_DESCRIPTION = `You can ask your AI questions that it will answer based on the
content you’ve added. Any questions asked here will bypass your
Knowledge Base, and be answered only by any Knowledge Sources
available.`

const InternalKnowledgeBaseScrapingContainer = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <AdmithubOnly fallback={<NoMatch />}>
      <InternalToolsPageContainer>
        <div className="px-4 pt-4 text-mainstay-dark-blue-80 generative-ai-transactions-container">
          <h2>Knowledge Base Scraping</h2>
          {children}
        </div>
      </InternalToolsPageContainer>
    </AdmithubOnly>
  )
}

interface IFetchKnowledgeSources {
  showLoadingState?: boolean
  query?: string
  offset?: number
}

const KnowledgeBaseScrapingContent = ({
  include_scrape_attempts,
}: {
  include_scrape_attempts?: boolean
}) => {
  const [knowledgeSources, setKnowledgeSources] = useState<
    api.GenAIScrapeKnowledgeSourceShapeType[] | 'loading' | 'error'
  >('loading')
  const [knowledgeSourcesCount, setKnowledgeSourcesCount] = useState(0)
  const [scrapeAttempts, setScrapeAttempts] = useState<
    api.GenAIScrapeAttemptShapeType[] | 'loading' | 'error'
  >('loading')
  const cancelTokeHandlenRef = useRef<CancelTokenSource | null>(null)

  const fetchKnowledgeSources = useCallback(
    async (params?: IFetchKnowledgeSources) => {
      const { showLoadingState = true, query, offset } = params ?? {}
      if (showLoadingState) {
        setKnowledgeSources('loading')
      }
      if (cancelTokeHandlenRef.current) {
        cancelTokeHandlenRef.current.cancel()
      }
      cancelTokeHandlenRef.current = axios.CancelToken.source()
      const res = await api.generativeTextService.scrape.knowledgeSources.list(
        query,
        offset,
        cancelTokeHandlenRef.current.token
      )
      if (isLeft(res)) {
        if (res.left.kind === 'cancel') {
          return
        }
        setKnowledgeSources('error')
        return
      }
      setKnowledgeSources(res.right.results)
      setKnowledgeSourcesCount(res.right.count)
    },
    []
  )

  const fetchScrapeAttempts = useCallback(
    async (showLoadingState = true) => {
      if (!include_scrape_attempts) {
        return
      }
      if (showLoadingState) {
        setScrapeAttempts('loading')
      }

      const res = await api.generativeTextService.scrape.scrapeAttempts.list()
      if (isLeft(res)) {
        setScrapeAttempts('error')
        return
      }

      setScrapeAttempts(res.right)
    },
    [include_scrape_attempts]
  )

  return (
    <>
      <QueryView />
      <div className="section-divider mt-4" />
      <div className="mt-4">
        <KnowledgeSourcesView
          knowledgeSources={knowledgeSources}
          totalCount={knowledgeSourcesCount}
          fetchKnowledgeSources={fetchKnowledgeSources}
          fetchScrapeAttempts={fetchScrapeAttempts}
        />
      </div>

      {include_scrape_attempts && (
        <div className="mt-4">
          <ScrapeAttempts
            scrapeAttempts={scrapeAttempts}
            fetchKnowledgeSources={fetchKnowledgeSources}
            fetchScrapeAttempts={fetchScrapeAttempts}
          />
        </div>
      )}
    </>
  )
}

const AskAITextBox = ({
  query,
  loading,
  onChange,
  onSubmit,
}: {
  query: string
  loading: boolean
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onSubmit: () => void
}) => {
  const handleEnterKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        onSubmit()
      }
    },
    [onSubmit]
  )

  return (
    <div className="position-relative">
      <FancyTextArea
        resizable={false}
        rows={4}
        onChange={onChange}
        onPressEnter={handleEnterKeyPress}
        value={query}
        placeholder="Ask the AI..."
        className="bg-transparent mb-2 text-area"
      />
      <FancyTextBoxSendButton
        disabled={!query.trim().length || loading}
        onClick={onSubmit}
      />
    </div>
  )
}

const QueryView = () => {
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()
  const answer = useSelector(state => state.askAI.response)

  const search = useCallback(async () => {
    const sentQuery = query
    dispatch(
      setMostRecentAIResponse({
        kind: 'loading',
        query: sentQuery,
      })
    )
    setQuery('')

    const res = await api.generativeTextService.scrape.search({
      query,
    })
    if (isLeft(res)) {
      dispatch(
        setMostRecentAIResponse({
          kind: 'error',
          query: sentQuery,
        })
      )
      return
    }
    dispatch(
      setMostRecentAIResponse({
        kind: 'answer',
        query: sentQuery,
        answer: res.right.answer,
        transactionId: res.right.transaction_id,
      })
    )
  }, [dispatch, query])

  const [showDrawer, setShowDrawer] = useState(false)

  return (
    <section>
      <div className="section-divider mb-3" />
      <div className="d-flex flex-row">
        <div>
          <h3 className="text-mainstay-dark-blue">Ask The AI</h3>
          <p className="mb-3">{ASK_THE_AI_DESCRIPTION}</p>
        </div>
      </div>
      <Button color="primary" outlined onClick={() => setShowDrawer(true)}>
        {' '}
        Ask The AI{' '}
      </Button>
      <PanelDrawer
        header={
          <PanelDrawerHeader
            title="Ask the AI"
            icon={<BotIconFilled className="mr-2 ml-4" />}
            onClose={() => setShowDrawer(false)}
          />
        }
        panelContainerClassName="h-100 px-4"
        onClose={() => setShowDrawer(false)}
        open={showDrawer}>
        <div className="pb-5 h-100">
          <p className="text-mainstay-dark-blue">{ASK_THE_AI_DESCRIPTION}</p>
          <div className="kb-scraping-conversations">
            {answer.kind === 'initial' ? null : answer.kind === 'loading' ? (
              <QueryConversation
                query={answer.query}
                answer={<ThinkingEllipses />}
              />
            ) : answer.kind === 'error' ? (
              <QueryConversation
                query={answer.query}
                answer="Sorry, something went wrong and I can't answer your question right now. Please try again later."
              />
            ) : (
              <QueryConversation
                query={answer.query}
                answer={answer.answer}
                transactionId={answer.transactionId}
              />
            )}
          </div>
          <div className="kb-scraping-text-box">
            <AskAITextBox
              query={query}
              loading={answer.kind === 'loading'}
              onSubmit={search}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </div>
      </PanelDrawer>
    </section>
  )
}

const QueryConversation = ({
  query,
  answer,
  transactionId,
}: {
  readonly query: string | JSX.Element
  readonly answer: string | JSX.Element
  readonly transactionId?: number
}) => {
  const messageOrigin: MessageOrigin | null = !!transactionId
    ? { generativeTransactionId: transactionId, kind: 'ai_generated' }
    : null
  return (
    <div className="d-flex flex-column mt-3 mb-4">
      <div className="align-self-start">
        <Message
          id=""
          direction="incoming"
          text={query}
          origin={null}
          sourceText={null}
        />
      </div>
      <div className="align-self-end mt-4">
        <div>
          <Message
            id=""
            direction="outgoing"
            text={answer}
            origin={messageOrigin}
            sourceText={null}
          />
        </div>
      </div>
    </div>
  )
}

const KnowledgeSourcesView = ({
  knowledgeSources,
  totalCount,
  fetchKnowledgeSources,
  fetchScrapeAttempts,
}: {
  readonly knowledgeSources:
    | api.GenAIScrapeKnowledgeSourceShapeType[]
    | 'loading'
    | 'error'
  readonly totalCount: number
  readonly fetchKnowledgeSources: (
    params?: IFetchKnowledgeSources
  ) => Promise<void>
  readonly fetchScrapeAttempts: (showLoader?: boolean) => Promise<void>
}) => {
  const location = useLocation()
  const history = useHistory()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string | undefined>()
  const [currentPage, setCurrentPage] = useState(1)
  const debouncedSearchQuery = useDebounce(searchQuery, 600)
  const [initialLoad, setInitialLoad] = useState(true)

  const fetchKnowledgeSourcesWithQueryParams = useCallback(
    async (showLoadingState?: boolean) => {
      await fetchKnowledgeSources({
        query: debouncedSearchQuery,
        offset: (currentPage - 1) * KNOWLEDGE_SOURCES_PAGE_SIZE,
        showLoadingState,
      })
    },
    [fetchKnowledgeSources, debouncedSearchQuery, currentPage]
  )

  const editKnowledgeSource = useCallback(
    async (knowledgeSource: api.GenAIScrapeKnowledgeSourceShapeType) => {
      const res = await api.generativeTextService.scrape.knowledgeSources.update(
        {
          id: knowledgeSource.id,
          title: knowledgeSource.title,
          description: knowledgeSource.description,
        }
      )
      if (isLeft(res)) {
        toast('Failed to update knowledge source', {
          type: 'error',
        })
        return
      }

      await fetchKnowledgeSourcesWithQueryParams()
    },
    [fetchKnowledgeSourcesWithQueryParams]
  )

  const archiveKnowledgeSource = useCallback(
    async (knowledgeSource: api.GenAIScrapeKnowledgeSourceShapeType) => {
      const res = await api.generativeTextService.scrape.knowledgeSources.archive(
        {
          id: knowledgeSource.id,
        }
      )
      if (isLeft(res)) {
        toast('Failed to archive knowledge source', {
          type: 'error',
        })
        return
      }

      await fetchKnowledgeSourcesWithQueryParams()
    },
    [fetchKnowledgeSourcesWithQueryParams]
  )

  useEffect(() => {
    fetchKnowledgeSourcesWithQueryParams()
    setInitialLoad(false)
  }, [fetchKnowledgeSourcesWithQueryParams])

  useInterval(() => {
    if (!initialLoad && document.hasFocus()) {
      fetchKnowledgeSourcesWithQueryParams(false)
    }
  }, FETCH_KNOWLEDGE_SOURCES_DELAY_MS)

  useEffect(() => {
    if (debouncedSearchQuery === '') {
      history.replace(removeQueryFilter(window.location, 'q'))
    } else if (!isUndefined(debouncedSearchQuery)) {
      history.replace(
        appendQueryFilter(window.location, 'q', debouncedSearchQuery)
      )
    }
  }, [history, debouncedSearchQuery])

  const triggerScrape = useCallback(
    async (knowledgeSource: api.GenAIScrapeKnowledgeSourceShapeType) => {
      const res = await api.generativeTextService.scrape.triggerScrape({
        knowledgeSourceIDs: [knowledgeSource.id],
      })

      if (isLeft(res)) {
        toast('Failed to trigger scrape', { type: 'error' })
        return
      }
      toast(`Triggered scrape of ${getKnowledgeSourceTitle(knowledgeSource)}`)

      await Promise.all([
        fetchKnowledgeSourcesWithQueryParams(),
        fetchScrapeAttempts(),
      ])
    },
    [fetchKnowledgeSourcesWithQueryParams, fetchScrapeAttempts]
  )

  const triggerScrapeAll = useCallback(async () => {
    const res = await api.generativeTextService.scrape.triggerScrape({
      knowledgeSourceIDs: undefined,
    })

    if (isLeft(res)) {
      toast('Failed to trigger scrape all', { type: 'error' })
      return
    }
    toast(`Triggered scrape of ${res.right.length} knowledge source(s)`)

    await Promise.all([
      fetchKnowledgeSourcesWithQueryParams(),
      fetchScrapeAttempts(),
    ])
  }, [fetchKnowledgeSourcesWithQueryParams, fetchScrapeAttempts])

  const bulkCreateKnowledgeSource = useCallback(
    async (
      knowledgeSources: api.GenAIScrapeKnowledgeSourceCreateReqShapeType[]
    ) => {
      const res = await api.generativeTextService.scrape.knowledgeSources.bulkCreate(
        { knowledgeSources }
      )
      if (isLeft(res)) {
        toast('Failed to create knowledge sources', {
          type: 'error',
        })
        return
      }

      await fetchKnowledgeSourcesWithQueryParams()
    },
    [fetchKnowledgeSourcesWithQueryParams]
  )

  const crawlURL = useCallback(
    async (sourceURL: string) => {
      const res = await api.generativeTextService.scrape.knowledgeSources.crawl(
        { sourceURL }
      )
      if (isLeft(res)) {
        toast('Failed to crawl website', {
          type: 'error',
        })
        return
      }

      await fetchKnowledgeSourcesWithQueryParams()
    },
    [fetchKnowledgeSourcesWithQueryParams]
  )

  const numberOfPages = Math.ceil(totalCount / KNOWLEDGE_SOURCES_PAGE_SIZE)
  useEffect(() => {
    const pageNumberFromParam = getQueryFilters(location)['page']
    if (typeof pageNumberFromParam === 'string') {
      const intPageNumberFromParam = parseInt(pageNumberFromParam, 10) || 1
      if (intPageNumberFromParam <= numberOfPages) {
        setCurrentPage(intPageNumberFromParam)
      }
    }
    const searchStringFromParam = getQueryFilters(location)['q']
    if (typeof searchStringFromParam === 'string') {
      setSearchQuery(searchStringFromParam)
    }
  }, [location, numberOfPages])

  const scrapeDoc = useCallback(
    async (sourceURL: string) => {
      const res = await api.generativeTextService.scrape.knowledgeSources.scrapeDoc(
        { sourceURL }
      )
      if (isLeft(res)) {
        const fallback = 'Failed to scrape doc'
        const message =
          'http' in res.left
            ? keyOrDefault(res.left.http, 'error', fallback)
            : fallback
        toast(message, {
          type: 'error',
        })
        return
      }

      await fetchKnowledgeSourcesWithQueryParams()
    },
    [fetchKnowledgeSourcesWithQueryParams]
  )

  return (
    <>
      <CreateKnowledgeSourceModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        bulkCreateKnowledgeSource={bulkCreateKnowledgeSource}
        crawlURL={crawlURL}
        scrapeDoc={scrapeDoc}
      />

      <section>
        <h3 className="text-mainstay-dark-blue">Knowledge Sources</h3>
        <p className="mb-4">
          Build your knowledge base by referencing information from your
          websites or documents.
        </p>
        <div className="d-flex flex-row flex-wrap justify-content-between">
          <div className="d-flex flex-row">
            <PermissionsGuard permission={PERMISSIONS.KNOWLEDGE_SOURCE.CREATE}>
              <Button
                onClick={() => setCreateModalOpen(true)}
                className="mr-3"
                color="secondary-teal">
                <div className="d-flex flex-row align-items-center">
                  <AHIcon name="add_circle_outline" />
                  <span>New Source</span>
                </div>
              </Button>
            </PermissionsGuard>
            <Button
              className="border-mainstay-dark-blue-80 bg-white text-mainstay-dark-blue-80"
              onClick={() => triggerScrapeAll()}>
              <AHIcon className="ahicon-reset" name="reset" />
              Scrape All
            </Button>
          </div>
          <SearchInput
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            placeholder="Search"
            name="search"
          />
        </div>
        <div>
          {knowledgeSources === 'loading' ? (
            <div className="d-flex flex-row align-center height-250px">
              <CenteredLoader className="w-100 " />
            </div>
          ) : knowledgeSources === 'error' ? (
            <p>Failed to load knowledge sources</p>
          ) : (
            <div className="d-flex flex-wrap mt-2">
              {knowledgeSources.length > 0 ? (
                <>
                  {knowledgeSources.map(knowledgeSource => (
                    <KnowledgeSource
                      key={`knowledge-base-scraping-knowledge-source-${knowledgeSource.id}`}
                      knowledgeSource={knowledgeSource}
                      editKnowledgeSource={editKnowledgeSource}
                      archiveKnowledgeSource={() =>
                        archiveKnowledgeSource(knowledgeSource)
                      }
                      triggerScrape={() => triggerScrape(knowledgeSource)}
                    />
                  ))}
                  {totalCount && (
                    <div className="py-5 w-100">
                      <Pager
                        urlFormat={x =>
                          appendQueryFilter(location, 'page', x, false)
                        }
                        current={currentPage}
                        onClick={e =>
                          setCurrentPage(
                            parseInt(e.currentTarget.dataset.page || '1', 10) ||
                              1
                          )
                        }
                        last={numberOfPages}
                      />
                    </div>
                  )}
                </>
              ) : (
                <p className="py-4">
                  {debouncedSearchQuery
                    ? 'No knowledge sources found based on search.'
                    : 'No knowledge sources'}
                </p>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  )
}

type SourceTypeSelectOptionType = {
  label: string
  value: SourceTypeValue
}

const SourceTypeSelectOptions: SourceTypeSelectOptionType[] = [
  {
    label: 'Specific Webpages',
    value: api.GenAIScapeKnowledgeSourceType.URL,
  },
  {
    label: 'Domain/Site Section',
    value: api.GenAIScapeKnowledgeSourceType.WEBSITE,
  },
  {
    label: 'PDF',
    value: api.GenAIScapeKnowledgeSourceType.PDF,
  },
  {
    label: 'Google Document',
    value: api.GenAIScapeKnowledgeSourceType.DOC,
  },
]

const newSourceScrapeInitialValues = {
  originUrl: '',
  sourceType: api.GenAIScapeKnowledgeSourceType.URL,
}

const VALID_URL_MESSAGE =
  'Must enter valid URLs, including a protocol (ex: https://) and domain (ex: example.com).'

const newSourceScrapeInitialValuesValidationSchema = yup.object().shape({
  sourceType: yup.string().required('Source type is required.'),
  originUrl: yup
    .string()
    .when('sourceType', (sourceType: string, schema: yup.StringSchema) => {
      if (sourceType === api.GenAIScapeKnowledgeSourceType.URL) {
        return schema
          .required('One or more origin URLs are required.')
          .test(
            'all-valid-urls',
            `${VALID_URL_MESSAGE} Each URL must be in its own line.`,
            (originUrlValues: string) => {
              return originUrlValues
                .split(/\r?\n/)
                .filter(url => !!url.trim())
                .every(url => VALID_URL_REGEX.test(url))
            }
          )
      }
      return schema.test('is-valid-url', VALID_URL_MESSAGE, (url: string) =>
        VALID_URL_REGEX.test(url)
      )
    }),
})

// Consolidated copy for the New Source modal
type SourceTypeCopy = {
  [key in api.GenAIScapeKnowledgeSourceType]: string
}

const sourceTypePlaceholderCopy: SourceTypeCopy = {
  [api.GenAIScapeKnowledgeSourceType.URL]:
    'Enter specific URLs on separate lines:\nhttps://example_one.edu\nhttps://example_two.edu/some/path',
  [api.GenAIScapeKnowledgeSourceType.WEBSITE]:
    'Enter an entire domain or section to crawl',
  [api.GenAIScapeKnowledgeSourceType.PDF]: 'Enter the URL of a hosted PDF',
  [api.GenAIScapeKnowledgeSourceType.DOC]:
    'Enter the URL of a shared Google Doc',
}

const CreateKnowledgeSourceModal = ({
  open,
  setOpen,
  bulkCreateKnowledgeSource,
  crawlURL,
  scrapeDoc,
}: {
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
  readonly bulkCreateKnowledgeSource: (
    knowledgeSources: api.GenAIScrapeKnowledgeSourceCreateReqShapeType[]
  ) => Promise<void>
  readonly crawlURL: (sourceURL: string) => Promise<void>
  readonly scrapeDoc: (sourceURL: string) => Promise<void>
}) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = async ({
    originUrl,
    sourceType,
  }: IAudienceFromContactLabels) => {
    setLoading(true)
    try {
      if (sourceType === api.GenAIScapeKnowledgeSourceType.URL) {
        const knowledgeSources = originUrl
          .split(/\r?\n/)
          .filter(url => !!url.trim())
          .map(url => {
            return {
              source_type: sourceType,
              origin_url: url,
            }
          })
        await bulkCreateKnowledgeSource(knowledgeSources)
      }
      if (sourceType === api.GenAIScapeKnowledgeSourceType.WEBSITE) {
        await crawlURL(originUrl)
      }
      if (
        sourceType === api.GenAIScapeKnowledgeSourceType.DOC ||
        sourceType === api.GenAIScapeKnowledgeSourceType.PDF
      ) {
        await scrapeDoc(originUrl)
      }
    } finally {
      setLoading(false)
    }
    setOpen(false)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={newSourceScrapeInitialValues}
      validationSchema={newSourceScrapeInitialValuesValidationSchema}
      onSubmit={onSubmit}>
      {formikProps => (
        <MainstayModal
          wrapContentInForm
          show={open}
          text="Create Knowledge Source"
          onClose={() => setOpen(!open)}
          submitText="Create"
          disableSubmit={
            loading ||
            !!formikProps.errors.originUrl ||
            !!formikProps.errors.sourceType
          }
          loading={loading}>
          <p className="mb-2">
            Build your knowledge base by referencing information online.
          </p>
          <ul className="mb-3 pl-3">
            <li className="text-muted fs-small">
              Specific Webpages: Scrape the text contents of the individual
              pages provided.
            </li>
            <li className="text-muted fs-small">
              Domain/Site Section: Scrape this page and all sub-pages.
            </li>
            <li className="text-muted fs-small">
              PDF: Scrape the text contents of a PDF file hosted online.
            </li>
            <li className="text-muted fs-small">
              Google Document: Scrape the text contents of a publicly accessible
              Google Doc.
            </li>
          </ul>
          <div>
            Source Type
            <span className="ml-1 color-mainstay-spark-red">*</span>
          </div>
          <Field
            name="sourceType"
            render={({ form }: FieldProps<IAudienceFromContactLabels>) => (
              <div>
                <Select<IOption>
                  className="mr-5"
                  defaultValue={{
                    value: form.values.sourceType,
                    label:
                      SourceTypeSelectOptions.find(
                        v => v.value === form.values.sourceType
                      )?.label ?? form.values.sourceType,
                  }}
                  options={SourceTypeSelectOptions}
                  onChange={selectedEntry => {
                    if (Array.isArray(selectedEntry)) {
                      return
                    }
                    if (selectedEntry?.value) {
                      form.setFieldValue('sourceType', selectedEntry.value)
                    }
                  }}
                  error={!!form.errors.sourceType && form.touched.sourceType}
                />
                {form.errors.sourceType && form.touched.sourceType && (
                  <div className="text-danger small">
                    {form.errors.sourceType}
                  </div>
                )}
              </div>
            )}
          />
          <Field
            name="originUrl"
            render={({
              form,
              field,
            }: FieldProps<IAudienceFromContactLabels>) => {
              return (
                <div>
                  <div className="mt-3">
                    <span>
                      URL
                      {form.values.sourceType ===
                        api.GenAIScapeKnowledgeSourceType.URL && '(s)'}
                    </span>
                    <span className="ml-1 color-mainstay-spark-red">*</span>
                  </div>
                  {form.values.sourceType ===
                  api.GenAIScapeKnowledgeSourceType.URL ? (
                    <TextArea
                      {...field}
                      id="originUrl"
                      placeholder={
                        sourceTypePlaceholderCopy[form.values.sourceType]
                      }
                      onChange={event => {
                        field.onChange(event)
                      }}
                      isValid={form.touched.originUrl && !form.errors.originUrl}
                    />
                  ) : (
                    <TextInput
                      {...field}
                      id="originUrl"
                      placeholder={
                        sourceTypePlaceholderCopy[form.values.sourceType]
                      }
                      onChange={event => {
                        field.onChange(event)
                      }}
                      error={!!form.errors.originUrl && form.touched.originUrl}
                    />
                  )}
                  {form.errors.originUrl && form.touched.originUrl && (
                    <div className="text-danger small">
                      {form.errors.originUrl}
                    </div>
                  )}
                </div>
              )
            }}
          />
          <section className="mt-4 d-flex justify-content-center">
            <AHIcon name="lock" className="text-muted" />{' '}
            <p>All content securely encrypted and privately stored.</p>
          </section>
        </MainstayModal>
      )}
    </Formik>
  )
}

function getKnowledgeSourceTitle(
  knowledgeSource: api.GenAIScrapeKnowledgeSourceShapeType
): string {
  const typeForTitle =
    knowledgeSource.source_type === api.GenAIScapeKnowledgeSourceType.URL
      ? 'Website'
      : 'Document'
  return (
    knowledgeSource.title || `${typeForTitle}: ${knowledgeSource.origin_url}`
  )
}

const KnowledgeSource = ({
  knowledgeSource,
  editKnowledgeSource,
  archiveKnowledgeSource,
  triggerScrape,
}: {
  readonly knowledgeSource: api.GenAIScrapeKnowledgeSourceShapeType
  readonly editKnowledgeSource: (
    knowledgeSource: api.GenAIScrapeKnowledgeSourceShapeType
  ) => Promise<void>
  readonly archiveKnowledgeSource: () => Promise<void>
  readonly triggerScrape: () => Promise<void>
}) => {
  const [showingEditModal, setShowingEditModal] = useState(false)

  const [archiveLoading, setArchiveLoading] = useState(false)
  const [triggerScrapeLoading, setTriggerScrapeLoading] = useState(false)

  const onArchive = async () => {
    setArchiveLoading(true)
    try {
      await archiveKnowledgeSource()
    } finally {
      setArchiveLoading(false)
    }
  }

  const onTriggerScrape = async () => {
    setTriggerScrapeLoading(true)
    try {
      await triggerScrape()
    } finally {
      setTriggerScrapeLoading(false)
    }
  }

  return (
    <>
      <EditKnowledgeSourceModal
        open={showingEditModal}
        setOpen={setShowingEditModal}
        knowledgeSource={knowledgeSource}
        editKnowledgeSource={editKnowledgeSource}
      />

      <Card
        className="knowledge-base-scraping-knowledge-source mt-2"
        title={
          <div className="d-flex flex-row w-100 justify-content-between">
            <h5 className="text-mainstay-dark-blue">
              {getKnowledgeSourceTitle(knowledgeSource)}
            </h5>
            <ActionMenu
              popoverPlacement="bottom-end"
              className="mt-2"
              stopPropagation={true}
              menuItems={[
                {
                  label: 'Edit',
                  icon: <AHIcon name="edit" />,
                  onSelect: () => setShowingEditModal(true),
                  permission: PERMISSIONS.KNOWLEDGE_SOURCE.EDIT,
                },
                {
                  label: 'Scrape',
                  icon: (
                    <>
                      {triggerScrapeLoading ? (
                        <Spinner
                          size="sm"
                          className="ml-1 mr-2 stroke-mainstay-dark-blue"
                        />
                      ) : (
                        <AHIcon name="cloud_download" />
                      )}
                    </>
                  ),
                  onSelect: onTriggerScrape,
                },
                {
                  label: 'Delete',
                  icon: (
                    <>
                      {archiveLoading ? (
                        <Spinner
                          size="sm"
                          className="ml-1 stroke-mainstay-dark-blue"
                        />
                      ) : (
                        <AHIcon name="delete" />
                      )}
                    </>
                  ),
                  onSelect: onArchive,
                  permission: PERMISSIONS.KNOWLEDGE_SOURCE.DELETE,
                },
              ]}
            />
          </div>
        }>
        <table>
          <tbody>
            <tr>
              <th>URL</th>
              <td className="pl-2">
                <a
                  href={knowledgeSource.origin_url}
                  target="_blank"
                  className="m-0">
                  {knowledgeSource.origin_url}
                </a>
              </td>
            </tr>
            <tr>
              <th>Last Scraped</th>
              <td className="pl-2">
                {knowledgeSource.scrape_date
                  ? moment(knowledgeSource.scrape_date).format(
                      'YYYY-MM-DD hh:mm A'
                    )
                  : 'Never'}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td className="pl-2 pt-1 d-flex align-items-center">
                <Pill
                  className="w-content"
                  text={knowledgeSource.latest_scrape_status || 'UNKNOWN'}
                  color={getColorForScrapeStatus(
                    knowledgeSource.latest_scrape_status
                  )}
                />
                {knowledgeSource.latest_scrape_status ===
                  api.GenAIScrapeAttemptStatus.FAIL && (
                  <span className="pl-2 text-mainstay-spark-red small">
                    {knowledgeSource.fail_reason}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </>
  )
}

const EditKnowledgeSourceModal = ({
  open,
  setOpen,
  knowledgeSource,
  editKnowledgeSource,
}: {
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
  readonly knowledgeSource: api.GenAIScrapeKnowledgeSourceShapeType
  readonly editKnowledgeSource: (
    knowledgeSource: api.GenAIScrapeKnowledgeSourceShapeType
  ) => Promise<void>
}) => {
  const [title, setTitle] = useState(knowledgeSource.title || '')
  const [description, setDescription] = useState(
    knowledgeSource.description || ''
  )

  const [editLoading, setEditLoading] = useState(false)

  const onEditSubmit = async () => {
    setEditLoading(true)

    try {
      await editKnowledgeSource({
        ...knowledgeSource,
        title: title.length > 0 ? title : null,
        description: description.length > 0 ? description : null,
      })
    } finally {
      setEditLoading(false)
    }

    onCancel()
  }

  const onCancel = () => {
    setTitle(knowledgeSource.title || '')
    setDescription(knowledgeSource.description || '')
    setOpen(false)
  }

  return (
    <MainstayModal
      show={open}
      text="Edit Knowledge Source"
      onClose={() => setOpen(!open)}
      onSubmit={onEditSubmit}
      disableSubmit={editLoading}
      loading={editLoading}>
      <p>
        The source type and URL cannot be modified. If you wish to change these
        values then archive this knowledge source and make a new one.
      </p>
      <div>
        <span>Title</span>
        <TextInput value={title} onChange={e => setTitle(e.target.value)} />
      </div>
      <div className="my-3">
        <span>Description</span>
        <TextArea
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </div>
    </MainstayModal>
  )
}

const ScrapeAttempts = ({
  scrapeAttempts,
  fetchKnowledgeSources,
  fetchScrapeAttempts,
}: {
  readonly scrapeAttempts:
    | api.GenAIScrapeAttemptShapeType[]
    | 'loading'
    | 'error'
  readonly fetchKnowledgeSources: () => Promise<void>
  readonly fetchScrapeAttempts: (showLoader?: boolean) => Promise<void>
}) => {
  const [triggerScrapeLoading, setTriggerScrapeLoading] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)

  const triggerScrape = useCallback(async () => {
    setTriggerScrapeLoading(true)

    const res = await api.generativeTextService.scrape.triggerScrape({
      knowledgeSourceIDs: undefined,
    })

    setTriggerScrapeLoading(false)

    if (isLeft(res)) {
      toast('Failed to trigger scrape', { type: 'error' })
      return
    }

    toast(`Triggered scrape of ${res.right.length} knowledge source(s)`)

    await Promise.all([fetchScrapeAttempts(), fetchKnowledgeSources()])
  }, [fetchScrapeAttempts, fetchKnowledgeSources])

  useEffect(() => {
    const _fetchScrapeAttempts = async () => {
      await fetchScrapeAttempts()
      setInitialLoad(false)
    }
    _fetchScrapeAttempts()
  }, [fetchScrapeAttempts])

  useInterval(() => {
    if (!initialLoad && document.hasFocus()) {
      fetchScrapeAttempts(false)
    }
  }, FETCH_SCRAPE_ATTEMPTS_DELAY_MS)

  return (
    <section>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row align-items-center">
          <h3>Scrape Attempts</h3>

          <ContainerButton
            onClick={() => fetchScrapeAttempts()}
            className="ml-3 d-flex flex-row align-items-center">
            <RefreshIcon className="mr-2" />
          </ContainerButton>
        </div>

        <Button
          disabled={
            triggerScrapeLoading ||
            scrapeAttempts === 'loading' ||
            scrapeAttempts === 'error'
          }
          onClick={triggerScrape}
          color="secondary-teal"
          outlined>
          <div className="d-flex flex-row align-items-center">
            <div>
              {(triggerScrapeLoading && (
                <Spinner size="sm" className="mr-2 stroke-mainstay-dark-blue" />
              )) || <AHIcon name="cloud_download" className="d-flex" />}
            </div>
            <span>Scrape All</span>
          </div>
        </Button>
      </div>

      <div className="py-2">
        {scrapeAttempts === 'loading' ? (
          <div className="d-flex flex-row align-center">
            <Spinner size="sm" className="mr-2 stroke-mainstay-dark-blue" />
            Loading
          </div>
        ) : scrapeAttempts === 'error' ? (
          <p>Failed to load scrape attempts</p>
        ) : (
          <MainstayFlexTable className="mt-4">
            <MainstayFlexTableHeader>
              <MainstayFlexTableHeaderCol>
                Knowledge Source
              </MainstayFlexTableHeaderCol>

              <MainstayFlexTableHeaderCol>Status</MainstayFlexTableHeaderCol>

              <MainstayFlexTableHeaderCol>
                Status Modified At
              </MainstayFlexTableHeaderCol>

              <MainstayFlexTableHeaderCol sm={1} />
            </MainstayFlexTableHeader>

            {scrapeAttempts.map(scrapeAttempt => (
              <ScrapeAttemptRow
                key={`knowledge-base-scraping-scrape-attempt-${scrapeAttempt.id}`}
                scrapeAttempt={scrapeAttempt}
              />
            ))}
          </MainstayFlexTable>
        )}
      </div>
    </section>
  )
}

const ScrapeAttemptRow = ({
  scrapeAttempt,
}: {
  readonly scrapeAttempt: api.GenAIScrapeAttemptShapeType
}) => {
  const [showPopoverActions, setShowPopoverActions] = useState(false)
  const [showStatusMessage, setShowStatusMessage] = useState(false)

  const popover = () => {
    return (
      <ButtonGroup vertical>
        <ActionButton
          onClick={() => {
            setShowPopoverActions(false)
            setShowStatusMessage(true)
          }}>
          Show Status Message
        </ActionButton>
      </ButtonGroup>
    )
  }

  return (
    <>
      <MainstayModal
        hideFooter
        show={showStatusMessage}
        text="Status Messagee"
        onClose={() => setShowStatusMessage(!showStatusMessage)}>
        <code>{scrapeAttempt.status_message || 'No status message'}</code>
      </MainstayModal>
      <MainstayFlexTableRow>
        <MainstayFlexTableCol>
          {getKnowledgeSourceTitle(scrapeAttempt.knowledge_source)}
        </MainstayFlexTableCol>

        <MainstayFlexTableCol>
          <div className="d-flex">
            <Pill
              className="w-content"
              text={scrapeAttempt.status}
              color={getColorForScrapeStatus(scrapeAttempt.status)}
            />
          </div>
        </MainstayFlexTableCol>

        <MainstayFlexTableCol>
          {moment(scrapeAttempt.modified).format('YYYY-MM-DD hh:mm A')}
        </MainstayFlexTableCol>

        <MainstayFlexTableCol sm={1}>
          <PopoverComponent
            visible={showPopoverActions}
            onClickOutside={() => setShowPopoverActions(false)}
            popoverPlacement="bottom"
            renderPopper={popover}
            renderReference={() => (
              <MoreIcon
                className="pointer"
                onClick={() => setShowPopoverActions(true)}
              />
            )}
          />
        </MainstayFlexTableCol>
      </MainstayFlexTableRow>
    </>
  )
}

export const KNOWLEDGE_BASE_SCRAPING_DESCRIPTION =
  'Add sources of information to your bot that can be referenced in various generative AI uses throughout the platform (such as messages drafted during AI-Assisted Live Chat). Sources can come from websites, PDFs, and Google Docs.'

const KnowledgeBaseScraping = () => {
  const { FeaturesType, hasFeature } = useFeatures()
  if (!hasFeature(FeaturesType.GENERATIVE_AI_PARTNER_FACING_SCRAPER)) {
    return null
  }
  return (
    <PermissionsGuard
      permission={PERMISSIONS.KNOWLEDGE_SOURCE.CREATE}
      renderNothing>
      <NavBarContainer title="Knowledge" className="d-flex h-100">
        <KnowledgeSideBar
          pageContent={
            <>
              <PageHeader
                title="Knowledge Scraping"
                description={KNOWLEDGE_BASE_SCRAPING_DESCRIPTION}
              />
              <KnowledgeBaseScrapingContent />
            </>
          }
        />
      </NavBarContainer>
    </PermissionsGuard>
  )
}

function getColorForScrapeStatus(status: string | null) {
  if (status === api.GenAIScrapeAttemptStatus.STARTED) {
    return 'mainstay-blue-70'
  }
  if (status === api.GenAIScrapeAttemptStatus.SUCCESS) {
    return 'mainstay-success-500'
  }
  return 'mainstay-spark-red'
}

export const InternalKnowledgeBaseScraping = () => {
  return (
    <InternalKnowledgeBaseScrapingContainer>
      <KnowledgeBaseScrapingContent include_scrape_attempts />
    </InternalKnowledgeBaseScrapingContainer>
  )
}

export default KnowledgeBaseScraping
